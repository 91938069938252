import React, { Fragment, useState } from "react"
import Vimeo from "@u-wave/react-vimeo";
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import cx from 'classnames';
import { ChevronRight, ChevronDown, CheckCircle, AlertCircle, Layers, X } from 'react-feather';

const Solutions = () => {

    const [showDemo, setShowDemo] = useState();
    const [activeSlide, setActiveSlide] = useState(0);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const slides = [
        {
            title: <Fragment>Data Integration</Fragment>,
            content: <ul>
                <li className='sol-problem'>
                    <h4 className='sol-heading'><AlertCircle /> <span>Diverse data sources</span></h4>
                    <p>Asset managers increasingly rely on data from diverse sources that needs to be linked, aligned, and integrated into investment processes.</p>
                </li>
                <li className='sol-solution'>
                    <h4 className='sol-heading'><CheckCircle /> <span>Built-in data feeds &amp; integration</span></h4>
                    <p>Quotient&trade; provides built-in integration with data feeds from Refinitiv (QA Direct), Bloomberg, and S&P Market Intelligence across a common security master. Quotient&trade; also provides out-of-the-box support for widely-used datasets, including market data, fundamentals, estimates, economics, common index families as well as pre-built factor libraries. Quotient&trade; offers flexible data integration tools to tie-in custom or external content from a number of data stores, including SQL & Snowflake databases.</p>
                </li>
                <li className='sol-benefits'>
                    <h4 className='sol-heading'><Layers /> <span>Instant access, no coding required</span></h4>
                    <p>Quotient&trade; offers instant access to comprehensive financial data sources with no coding required. Quotient&trade; provides the ability to combine different timeseries data sets without having to handle normalization to common currencies, frequencies, etc. Quotient’s vendor integration supports value-added categorical navigation, content searches and in-line content documentation.</p>
                </li>
            </ul>
        },
        {
            title: <Fragment>Data Management</Fragment>,
            content: <ul>
                <li className='sol-problem'>
                    <h4 className='sol-heading'><AlertCircle /> <span>How to understand the data?</span></h4>
                    <p>Quotient&trade; allows investors to unlock insights into how data informs investment strategies. To build systematic investment processes, quantitative and “quantamental” asset managers initially need to:</p>
                    <ul className='list-bullet mt-2'>
                        <li>Manipulate and work with the data</li>
                        <li>Construct factors (investment signals)</li>
                        <li>Inspect, visualize and validate the inputs to and outputs of factor construction</li>
                    </ul>
                </li>
                <li className='sol-solution'>
                    <h4 className='sol-heading'><CheckCircle /> <span>Inspect integrated data directly</span></h4>
                    <p>With Quotient&trade;, investors can inspect and interact with integrated data feeds directly within the application. Quotient&trade; also offers direct access to powerful Python tools specifically designed for financial modeling and investment analysis. Users can access Python interfaces to manipulate and visualize raw and derived data items using programmatic IDE’s such as Jupyter Notebooks. Full stack applications can be built in Python to take advantage of Quotient’s data integration, security master identifier translation, as well as seamless currency and time-frequency conversions.</p>
                </li>
                <li className='sol-benefits'>
                    <h4 className='sol-heading'><Layers />  <span>Quickly build &amp; validate new factors</span></h4>
                    <p>Quotient's data integration capabilities allow users to build and validate new factors quickly in a few lines of code. Minimum coding is needed, as users can operate directly on data “Qubles” representing time-series data across configurable investment universes. With Quotient&trade;, building and evaluating investment models is quick, flexible and powerful.</p>
                </li>
            </ul>
        },
        {
            title: <Fragment>Machine Learning</Fragment>,
            content: <ul>
                <li className='sol-problem'>
                    <h4 className='sol-heading'><AlertCircle /> <span>Availability of data sets</span></h4>
                    <p>Increasingly, asset managers employ machine learning algorithms to construct and test non-linear factors against large datasets. Techniques such as NLP and dynamic factor weighting schemes can be applied to new and existing data sets and factor libraries.</p>
                </li>
                <li className='sol-solution'>
                    <h4 className='sol-heading'><CheckCircle /> <span>Seamless integration & management</span></h4>
                    <p>Quotient&trade; combines data integration and management capabilities with data science and NLP modeling libraries using Python – now the de facto language for data science applications. Quotient&trade; incorporates advanced data science packages such as scikit-learn and scipy.</p>
                </li>
                <li className='sol-benefits'>
                    <h4 className='sol-heading'><Layers /> <span>Quickly build & test algorithms</span></h4>
                    <p>Quotient&trade; allows users to explore machine learning algorithms on a wide range of vendor and proprietary data. Quotient&trade; delivers new insights from financial data.</p>
                </li>
            </ul>
        },
        {
            title: <Fragment>Analytics &amp; Reporting</Fragment>,
            content: <ul>
                <li className='sol-problem'>
                    <h4 className='sol-heading'><AlertCircle /> <span>Complex modeling &amp; simulations</span></h4>
                    <p>To extract value from data and develop systematic investment processes, portfolio managers and financial analysts need to perform analytical tests including factor back-testing, multi-factor model development, and historical simulations of portfolio construction.</p>
                </li>
                <li className='sol-solution'>
                    <h4 className='sol-heading'><CheckCircle /> <span>Pre-built analytical modules</span></h4>
                    <p>Quotient&trade; delivers a wide array of pre-built analytical modules such as screening, back-testing and reporting. These modules allow users to test the forecasting power of individual factors as well as to build and test complex multi-factor models. Quotient’s forecasting module offers a rich selection of linear and non-linear techniques, including machine learning, to build dynamic factor weighting schemes and perform out-of-sample model validation.</p>
                </li>
                <li className='sol-benefits'>
                    <h4 className='sol-heading'><Layers /> <span>Built-in analytics</span></h4>
                    <p>Users can leverage Quotient’s built-in analytics to generate reports that provide robust insights into the forecasting efficacy of single factor and multi-factor models. These reports can be run in production environments and shared within investment teams to facilitate fund management activities.</p>
                </li>
            </ul>
        },
    ];

    const renderMenu = () => {
        return (
            <Fragment>
                {
                    slides.map((_, i) => (
                        <li key={`solution-${i}`}>
                            <button
                                className={cx({ 'active': activeSlide === i })}
                                onClick={() => { setActiveSlide(i); setShowMobileMenu(false) }}
                            ><ChevronRight size="28" /><span> {slides[i].title}</span></button>
                        </li>
                    ))
                }
            </Fragment>
        )
    }

    return (
        <Layout showDemo={showDemo}>
            <Seo title='Solutions' />
            <div className="solutions-video">
                <div className="container">
                    <div className="solutions-video__vid">
                        <Vimeo
                            video="651357653"
                            responsive
                            width="400"
                            showByline={false}
                            showPortrait={false}
                            showTitle={false}
                        />
                    </div>
                </div>
            </div>
            <div className="section-muted mt-0 pt-0 solutions-wrapper">
                {
                    showMobileMenu && (
                        <div className='solutions__mobile-menu'>
                            <h1 className='uppercase solutions__heading'>Solutions <button className='d-inline d-md-none' onClick={() => setShowMobileMenu(false)}><X /></button></h1>
                            <ul className='solutions__menu-list'>
                                {renderMenu()}
                            </ul>
                        </div>
                    )
                }
                <div className="container pt-2 pt-md-4">
                    <div className="solutions">
                        <div className="solutions__menu">
                            <h1 className='uppercase solutions__heading'>Solutions <button className='d-inline d-md-none' onClick={() => setShowMobileMenu(true)}><ChevronDown /></button></h1>
                            <ul className='solutions__menu-list solutions__menu-list--desktop'>
                                {renderMenu()}
                            </ul>
                        </div>
                        <div className='solutions__content'>
                            <h3 className='uppercase my-2'>{slides[activeSlide].title}</h3>
                            <div>
                                {slides[activeSlide].content}
                            </div>
                            <button className='btn btn-primary btn-wide mb-2 mb-sm-0' onClick={setShowDemo}>Request demo of Quotient&trade;</button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Solutions;
